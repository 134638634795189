import { createTheme } from '@mui/material/styles';
import { blue, blueGrey, grey, orange, purple } from '@mui/material/colors';

// 800: '#ef6c00';
// 900: '#e65100';
const primaryColor = orange[800];
// purple[500];
const hoverPrimaryColor = orange[900];
// purple[200];

const theme = createTheme({
	palette: {
		primary: {
			main: primaryColor,
		},
	},
	typography: {
		// fontFamily: 'Comic Sans MS',
	},
	// shape: {
	// 	borderRadius: 20,
	// },

	components: {
		MuiButtonBase: {
			defaultProps: {
				// disableRipple: true,
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					height: '55px',
					borderRadius: 20,
					// backgroundColor: primaryColor,
					// '&: hover': {
					// 	// backgroundColor: hoverPrimaryColor,
					// },
				},
			},
		},
	},
});

export default theme;
