import React from 'react';
import {
	CardActionArea,
	Card,
	CardMedia,
	CardContent,
	Typography,
} from '@mui/material';

function CustomCard(props) {
	const { item, imageHeight } = props;

	return (
		<Card
			sx={{
				width: 300,
				'&:hover': { transform: 'scale(1.1, 1.1)', transition: '2s' },
			}}>
			<CardActionArea>
				<CardMedia
					component="img"
					height={imageHeight}
					// image="/images/spaceByStyle/house.jpg"
					image={item.img}
					alt={item.title}
				/>
				<CardContent>
					<Typography variant="h6">{item.title}</Typography>
					{item.views ? (
						<Typography variant="caption">
							Views {item.views} {item.title}
						</Typography>
					) : (
						''
					)}
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default CustomCard;
