import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, Divider } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const informationtItems = [
	{
		id: 'faq',
		name: 'Frequent Question & Answer',
		path: '/faq',
		icon: <ArrowRightIcon />,
	},
	{
		id: 'onlineHelp',
		name: 'Online Help',
		path: '/contactus',
		icon: <ArrowRightIcon />,
	},
	{
		id: 'equipment',
		name: 'Kitchen Equipments',
		path: '/equipment',
		icon: <ArrowRightIcon />,
	},
	{
		id: 'feedback',
		name: 'Feedback',
		path: '/contactus',
		icon: <ArrowRightIcon />,
	},
];

function FooterInformation() {
	return (
		<>
			<Grid direction="column" container spacing={2}>
				<Grid item>
					<Typography variant="subtitle2">INFORMATION</Typography>
				</Grid>
				<Divider sx={{ width: '80%' }} />
				{informationtItems.map((item) => (
					<Grid
						item
						key={item.id}
						component={Link}
						to={item.path}
						sx={{ display: 'flex', textDecoration: 'none', color: 'white' }}>
						{item.icon}
						<Typography variant="subtitle2">{item.name}</Typography>
					</Grid>
				))}
			</Grid>
		</>
	);
}

export default FooterInformation;
