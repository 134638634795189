import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Divider } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import VISA from "../../CustomIcons/images/visa.webp";
import MASTERCARD from "../../CustomIcons/images/mastercard.webp";
import AMEX from "../../CustomIcons/images/americanexpress.webp";
import DISCOVER from "../../CustomIcons/images/discover.webp";
import CASHAPP from "../../CustomIcons/images/cash_app.webp";
import VENMO from "../../CustomIcons/images/venmo.webp";

const serviceItems = [
    {
        id: "contactus",
        name: "Contact Us",
        path: "/contactus",
        icon: <ArrowRightIcon />,
    },
    // {
    //     id: "billing",
    //     name: "Billing",
    //     path: "/billing",
    //     icon: <ArrowRightIcon />,
    // },
    // {
    //     id: "schedule",
    //     name: "Schedule",
    //     path: "/schedule",
    //     icon: <ArrowRightIcon />,
    // },
    {
        id: "tourkitchen",
        name: "Tour Kitchen",
        path: "/contactus",
        icon: <ArrowRightIcon />,
    },
];

function FooterService() {
    return (
        <>
            <Grid direction="column" container spacing={2}>
                <Grid item>
                    <Typography variant="subtitle2">SERVICE</Typography>
                </Grid>
                <Divider sx={{ width: "80%" }} />
                {serviceItems.map((item) => (
                    <Grid
                        item
                        key={item.id}
                        component={Link}
                        to={item.path}
                        sx={{ display: "flex", textDecoration: "none", color: "white" }}
                    >
                        {item.icon}
                        <Typography variant="subtitle2">{item.name}</Typography>
                    </Grid>
                ))}
                <Grid item>
                    <Typography variant="subtitle2">We accept</Typography>
                    <Grid item container direction="row" gap={1}>
                        <img src={VISA} style={{ width: "40px", height: "25px" }} alt="Visa" />
                        <img src={MASTERCARD} style={{ width: "40px", height: "25px" }} alt="MasterCard" />
                        <img src={AMEX} style={{ width: "40px", height: "25px" }} alt="AmericanExpress" />
                        <img src={DISCOVER} style={{ width: "40px", height: "25px" }} alt="Discover" />
                        <img src={CASHAPP} style={{ width: "40px", height: "25px" }} alt="CashApp" />
                        <img src={VENMO} style={{ width: "40px", height: "25px" }} alt="Venmo" />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default FooterService;
