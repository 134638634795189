import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { IconButton, Grid } from "@mui/material";
import { useMediaQuery } from "@mui/material";

import theme from "./../../Theme/Theme";
//Icons

import HomeIcon from "@mui/icons-material/Home";

//Custom control

import DrawerMenu from "./DrawerMenu";
import Header from "./Header";

export default function MenuAppBar() {
    // const theme = useTheme();
    const navigate = useNavigate();

    const matches = useMediaQuery(theme.breakpoints.down("md"));
    const handleGoHome = () => {
        navigate("/");
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                {/* <Toolbar> */}
                <Grid container spacing={4} justifyContent="flex-start">
                    <Grid item sx={{ flexGrow: 1 }}>
                        <IconButton
                            onClick={handleGoHome}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 4, pl: 4 }}
                        >
                            <HomeIcon />
                            Aire Kitchen
                        </IconButton>
                    </Grid>

                    <Grid item>
                        <Header />
                    </Grid>
                </Grid>

                {/* </Toolbar> */}
            </AppBar>
        </Box>
    );
}
