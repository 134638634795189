import React from "react";
import { Link } from "react-router-dom";
import { Grid, Typography, Divider } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { MdLocationOn } from "react-icons/md";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

const aboutItems = [
    {
        id: "aboutus",
        name: "About Us",
        path: "/about_us",
        icon: <InfoIcon />,
    },
    {
        id: "contactus",
        name: "Send Message",
        path: "/contactus",
        icon: <EmailIcon />,
    },
];

function FooterAbout() {
    return (
        <>
            <Grid direction="column" container spacing={2}>
                <Grid item>
                    <Typography variant="subtitle2">ABOUT</Typography>
                </Grid>
                <Divider sx={{ width: "80%" }} />
                {aboutItems.map((item) => (
                    <Grid
                        item
                        key={item.id}
                        component={Link}
                        to={item.path}
                        sx={{
                            display: "flex",
                            textDecoration: "none",
                            color: "white",
                        }}
                    >
                        {item.icon} &nbsp;&nbsp;
                        <Typography variant="subtitle2">{item.name}</Typography>
                    </Grid>
                ))}
                <Grid
                    item
                    sx={{
                        display: "flex",
                    }}
                >
                    <PhoneIcon /> &nbsp;&nbsp;
                    <a href="tel:2088410019" style={{ textDecoration: "none" }}>
                        <Typography variant="subtitle2" sx={{ color: "white" }}>
                            208.841.0019
                        </Typography>
                    </a>
                </Grid>
                <Grid
                    item
                    sx={{
                        display: "flex",
                    }}
                >
                    <FmdGoodIcon />
                    <a
                        style={{ textDecoration: "none" }}
                        href="https://goo.gl/maps/bbLCxk8mZCPz8YrDA"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <Typography variant="subtitle2" sx={{ color: "white" }}>
                            10386 Ustick Rd
                        </Typography>
                        <Typography variant="subtitle2" sx={{ color: "white" }}>
                            Boise, ID 83704
                        </Typography>
                    </a>
                </Grid>
            </Grid>
        </>
    );
}

export default FooterAbout;
