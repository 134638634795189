import React from 'react';
import { useMediaQuery } from '@mui/material';

import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import theme from './../../Theme/Theme';

function Booking() {
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	let variantVal = matches ? 'subtitle2' : 'h6';
	return (
		<div>
			<Grid
				container
				justifyContent="center"
				spacing={{ xs: 2, sm: 2, md: 3, lg: 4 }}
				sx={{ p: matches ? 4 : 8 }}>
				<Grid item>
					<Typography variant={variantVal}>
						We are working on a booking online solution. In a meantime, please call &nbsp;
						<a href="tel:2088410019"> 208.841.0019 </a> &nbsp; or &nbsp;{' '}
						<Link to="/contactus"> Contact Us &nbsp;</Link>
						for booking.
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
}

export default Booking;
