import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Tabs, Tab, Typography, Grid } from '@mui/material';

//Icons

//Custom control

export default function Header() {
	return (
		// <>
		// 	<Box
		// 	// sx={{
		// 	// 	display: 'flex',
		// 	// 	alignItems: 'center',
		// 	// 	textAlign: 'center',
		// 	// 	justifyContent: 'space-around',
		// 	// 	marginRight: 0.5,
		// 	// }}
		// 	>
		<Grid container spacing={4} alignContent="center" sx={{ pt: 2, pl: 4 }}>
			<Grid item>
				<Typography
					sx={{ textDecoration: 'none' }}
					variant="h6"
					component={Link}
					to="/">
					Home
				</Typography>
			</Grid>
			<Grid item>
				<Typography
					sx={{ textDecoration: 'none' }}
					variant="h6"
					component={Link}
					to="/booking">
					Booking
				</Typography>
			</Grid>

			<Grid item>
				<Typography
					sx={{ textDecoration: 'none' }}
					variant="h6"
					component={Link}
					to="/contactus">
					Contact Us
				</Typography>
			</Grid>

			<Grid item>
				<a href="tel:2088410019" style={{ textDecoration: 'none' }}>
					<Typography variant="h6" sx={{ minWidth: '400px' }}>
						Call Us: 208.841.0019
					</Typography>
				</a>
			</Grid>
		</Grid>

		// 	</Box>
		// </>
	);
}
