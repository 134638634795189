import React from 'react';

import { Box, Grid, Typography, Divider } from '@mui/material';
import theme from './../../Theme/Theme';

import { ExploreUs, TheWhat, TheWhen, TheWhere, TheWhy } from './HomePageData';

import RadioButton from './RadioButton';
import MyCheckbox from './MyCheckbox';

function HomPage() {
	// const matches = useMediaQuery(theme.breakpoints.down('xs'));

	return (
		<Box
			sx={{
				m: 4,
				p: 4,

				display: 'flex',
			}}>
			<Grid spacing={4} container justifyContent="center">
				{/* The What */}
		
				<Grid item lg={6} sm={12} xs={12}>
					<Typography
						variant="h4"
						sx={{
							color: theme.palette.primary.main,
							mb: 3,
							fontWeight: 900,
						}}>
						{TheWhat.title}
					</Typography>

					<Typography variant="h5">{TheWhat.desc}</Typography>
				</Grid>
				<Grid item lg={6} sm={12} xs={12}>
					<img
						src={TheWhat.img}
						alt="thewhat"
						width="80%"
						height="400px"
					/>
				</Grid>

				{/* The Where */}
				<Divider sx={{ mt: 4, mb: 4, width: '80%' }} />

				<Grid item lg={6} sm={12} xs={12}>
					<img
						src={TheWhere.img}
						alt="Location"
						width="80%"
						height="100%"
					/>
				</Grid>

				<Grid item lg={6} sm={12} xs={12}>
					<Typography
						variant="h4"
						sx={{
							color: theme.palette.primary.main,
							mb: 3,
							fontWeight: 900,
						}}>
						{TheWhere.title}
					</Typography>

					<Typography variant="h5">{TheWhere.desc}</Typography>
				</Grid>

				{/* -------The Why-------- */}

				<Divider sx={{ mt: 4, mb: 4, width: '80%' }} />

				<Grid item lg={6} sm={12} xs={12}>
					{/* <Box sx={{ width: '500px', height: '500px' }}> */}
					<Typography
						variant="h4"
						sx={{
							color: theme.palette.primary.main,
							mb: 3,
							fontWeight: 900,
						}}>
						{TheWhy.title}
					</Typography>

					<Typography variant="h5">{TheWhy.desc}</Typography>
					{/* </Box> */}
				</Grid>
				<Grid item lg={6} sm={12} xs={12}>
					<img
						src={TheWhy.img}
						alt="baking"
						width="80%"
						// height="500"
					/>
				</Grid>

				{/* -------The When-------------------- */}

				<Divider sx={{ mt: 4, mb: 4, width: '80%' }} />
				<Grid item lg={6} sm={12} xs={12}>
					<img
						src={TheWhen.img}
						alt="baking"
						width="80%"
						// height="500"
					/>
				</Grid>
				<Grid item lg={6} sm={12} xs={12}>
					<Typography
						variant="h4"
						sx={{
							color: theme.palette.primary.main,
							mb: 3,
							fontWeight: 900,
						}}>
						{TheWhen.title}
					</Typography>

					<Typography variant="h5">{TheWhen.desc}</Typography>
					{/* </Box> */}
				</Grid>

				{/* --------------------------- */}
				<Divider sx={{ mt: 4, mb: 4, width: '80%' }} />
				<Grid item lg={6} sm={12} xs={12}>
					<Typography
						variant="h4"
						sx={{
							color: theme.palette.primary.main,
							mb: 3,
							fontWeight: 900,
						}}>
						{ExploreUs.title}
					</Typography>

					<Typography variant="h5">{ExploreUs.desc}</Typography>
				</Grid>
				<Grid item lg={6} sm={12} xs={12}>
					<img
						src={ExploreUs.img}
						alt="baking"
						width="80%"
						// height="50%"
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

export default HomPage;
