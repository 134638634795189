export const TheWhat = {
	title: 'The What?',
	desc: 'AireKitchen is a commercial kitchen, Rent By The Hour Kitchen, and open 24/7.  Yes, that’s right, we are open 24/7.  The kitchen is equipped with all commercial cooking grade equipment that can produce mass amount of foods in a short time that will save time and money.  It’s for rent by the hour so no long-term contract is needed.  You can just book the hour you need.  Eg. If your cooking only needs 2 hours then you only need to book 2 hours.',
	img: '/images/Kitchen/theWhatFront.jpg',
};

export const TheWhere = {
	title: 'The Where?',
	desc: 'We are located at 10386 Ustick Rd. Boise ID 83704.  The facility is about 3600 sq. ft.   The location is close to the intersection of Five Mile and Ustick which is perfect for reaching anywhere in the Treasure Valley.  There are plenty of parking space that can be used for carry-out or big event or cooking class.',
	img: '/images/Kitchen/map.jpg',
};

export const TheWhen = {
	title: 'The When?',
	desc: 'Cook, Bake at any time.  We are open all year long 365/24/7.  You can book the spot at any time and access the kitchen based on your booking schedule.  Eg. If you book everyday from 8 AM to 11 AM, then you can access the kitchen during then timed book.',
	img: '/images/Kitchen/open247.jpg',
};

export const TheWhy = {
	title: 'The Why?',
	desc: 'Why pay too much overhead? Or Have you ever thought about startup a businnes but worry the overhead cost is too high?  Have you ever thought running a bussiness without a long term contract?  Have you ever thought be your own boss at your own hours? Or you just want to try out your concept? If the answer is "Yes" then thi is for you. At Aire Kitchen, we have done all the overhead and setup all the equipments for you to come in and expand your business.',
	img: '/images/Kitchen/cooking.jpg',
};

export const ExploreUs = {
	title: 'Explore The Possibilities',
	desc: 'We believe the potential of cooking are endless.  At Aire Kitchen, we design such kitchen to unlock those  potentials to a new possiblities that might have not been thought of before.  Not every cooking are the same, that is why we setup a whole range of variety cooking equipments that will help you to expand you bussiness while miminize the overhead cost.',
	img: '/images/Kitchen/baking.jpg',
};
