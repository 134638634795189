import React, { useState, createContext, useContext } from "react";

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

const openStateValue = {
    openSignUp: false,
    openSignUpByEmailStep2: false,
    openSigUpByPhoneStep2: false,
};
const userStateValue = {
    email: "",
    password: "",
    phone: "+1",
    phoneCode: "",
};
function UserContextProvider(props) {
    const [userValue, setUserValue] = useState(userStateValue);
    const [openState, setOpenState] = useState(openStateValue);

    const handleOpenState = (member, value) => {
        // console.log(openState);
        setOpenState((prev) => ({ ...prev, [member]: value }));
    };

    const handleUserChange = (event) => {
        // console.log('name=' + event.target.name);
        // console.log('value=' + event.target.value);
        setUserValue((prev) => ({
            ...prev,
            [event.target.name]: event.target.value,
        }));
        console.log(userValue);
    };
    // useEffect(() => {
    // 	console.log(openState);
    // 	console.log(userStateValue);
    // }, [openState]);

    const values = {
        userValue,
        setUserValue,
        openState,
        setOpenState,
        handleOpenState,
        handleUserChange,
    };

    return (
        <UserContext.Provider value={values}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;

// const [values, setValues] = React.useState({
//     amount: '',
//     password: '',
//     weight: '',
//     weightRange: '',
//     showPassword: false,
// });
// const handleChange = (prop) => (event) => {
//     setValues({ ...values, [prop]: event.target.value });
// };
// const handleClickShowPassword = () => {
//     setValues({
//         ...values,
//         showPassword: !values.showPassword,
//     });
// };

// const handleMouseDownPassword = (event) => {
//     event.preventDefault();
// };
