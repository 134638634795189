import React from "react";
import { Box, Grid, Typography } from "@mui/material";

export default function FooterBottom() {
    return (
        <Box
            sx={{
                display: "flex",
                backgroundColor: "black",
                color: "whitesmoke",
                height: "40px",
            }}
        >
            <Grid container>
                <Grid item>
                    <Typography variant="caption">
                        Copyright &copy; 2021-2024 by Aire Kitchen. All Rights Reserved.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}
