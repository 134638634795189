import React from 'react';
import { Paper, Box, Grid, Typography } from '@mui/material';

import theme from './../../Theme/Theme';

import { AboutUsObject } from './AboutUsData';

function AboutUs() {
	return (
		<Box sx={{ width: '100%' }}>
			<Paper elevation={1} sx={{ width: '50%', p: 4, ml: '20%' }}>
				<Grid container direction="column">
					<Grid item>
						<Typography
							variant="h4"
							sx={{ color: theme.palette.primary.main, mb: 3 }}>
							{AboutUsObject.title}
						</Typography>

						<Typography variant="h6">
							{AboutUsObject.desc}
						</Typography>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
}

export default AboutUs;
