import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';

function ContactUsFailure() {
	let { firstName } = useParams();
	return (
		<Typography variant="h6">
			Thank you, {firstName}, for contacting us. However, your message
			have failed to send.
		</Typography>
	);
}

export default ContactUsFailure;
