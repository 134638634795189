import React from 'react';
import { Button as MuiButton } from '@mui/material';
// import { makeStyles } from '@mui/styles';

export default function Button(props) {
	const { text, size, color, variant, onClick, ...other } = props;
	return (
		<MuiButton
			sx={{
				marginRight: 0.5,
				backgroundColor: 'transparent',
			}}
			variant={variant || 'contained'}
			disableElevation
			size={size || 'large'}
			color={color || 'primary'}
			onClick={onClick}
			{...other}>
			{text}
		</MuiButton>
	);
}

//
