import React, { useState } from 'react';
import { FcCheckmark } from 'react-icons/fc';
import './style.css';

import styled from 'styled-components';

const Input = styled.input.attrs({ type: 'checkbox' })``;

const StyledCheckBoxContainer = styled.label`
	align-items: center;
	justify-content: center;
	flex-direction: column;
	${Input} {
		display: none;
	}
`;

const StyledItatic = styled.i`
	color: #fff;
	background-color: #8373e6;
	font-size: 20px;
	position: absolute;
	top: -15px;
	left: 50%;
	transform: translateX(-50%) scale(2);
	border-radius: 50px;
	padding: 3px;
	transition: 0.2s;
	pointer-events: none;
	opacity: 0;
`;
// .checkbox-btn
const StyledCheckBoxSpan = styled.span`
	margin: 10px;
	width: 180px;
	height: 200px;
	border: 1px solid transparent;
	border: 0.5px solid #286ab0;

	display: inline-block;
	border-radius: 10px;
	position: relative;
	text-align: center;
	box-shadow: '0 0 20px #f3eeee67';
	cursor: pointer;
	${Input}:checked + && {
		border: 3px solid #8373e6;
		${StyledItatic} {
			opacity: 1;
			transform: translateX(-50%) scale(1);
		}
	}
`;

function MyCheckbox() {
	const [check, setCheck] = useState(0);

	const handleCheck = (event) => {
		console.log(event.target.checked);
		setCheck(event.target.checked);
	};
	return (
		// <div class="checkbox-container">
		<StyledCheckBoxContainer>
			<Input value={check} onChange={handleCheck} />
			<StyledCheckBoxSpan>
				<StyledItatic>
					<FcCheckmark />
				</StyledItatic>
				<h3> HI</h3>
			</StyledCheckBoxSpan>
		</StyledCheckBoxContainer>

		// <label class="custom-checkbox">
		// 	<input
		// 		type="checkbox"
		// 		name="checkbox"
		// 		checked={check}
		// 		onChange={handleCheck}
		// 	/>
		// 	<span class="checkbox-btn">
		// 		<i>
		// 			<FcCheckmark />
		// 		</i>
		// 		<h3> HI</h3>
		// 	</span>
		// </label>
	);
}
export default MyCheckbox;
