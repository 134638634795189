import React from 'react';
import { Typography, Box } from '@mui/material';
import Carousel from 'react-elastic-carousel';

//custom component
import CustomCard from './CustomCard';
import { KitchenObject } from './KitchenCarouselDisplayData';

import theme from './../../Theme/Theme'

const handleCardClick = () => {
	console.log('handle Card Click called...');
};

const breakPoints = [
	{ width: 1, itemsToShow: 1 },
	{ width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
	{ width: 850, itemsToShow: 3 },
	{ width: 1150, itemsToShow: 4, itemsToScroll: 2 },
	{ width: 1450, itemsToShow: 5 },
	{ width: 1750, itemsToShow: 6 },
];

function KitchenCarouselDisplay() {
	return (
		<Box sx={{ m: 4 }}>
			<Typography
				variant="h3"
				component="h3"
				sx={{
					mb: 2,
					color: theme.palette.primary.main,
					display: 'flex',
					justifyContent: 'center',
					fontWeight: 600
				}}>
				The Kitchen for Rent by the hour.  Open 24/7.
			</Typography>
			<Carousel itemsToShow={3} breakPoints={breakPoints}>
				{KitchenObject.map((item) => (
					<CustomCard
						key={item.id}
						item={item}
						imageHeight={250}
						onClick={handleCardClick}
					/>
				))}
			</Carousel>
		</Box>
	);
}

export default KitchenCarouselDisplay;
