import { Typography, Box } from '@mui/material';
import React from 'react';
import { useParams } from 'react-router';

function ContactUsSuccess() {
	const { firstName } = useParams();

	const res = firstName ? (
		<Typography variant="h6">
			Thank You, {firstName}, for contacting us. We will get back to you
			as soon as we can.
		</Typography>
	) : (
		''
	);
	return <Box sx={{ display: 'flex', justifyContent: 'center' }}>{res}</Box>;
}

export default ContactUsSuccess;
