import { TextField, Box, Paper, Grid, Typography, Button } from '@mui/material';
import React, { useState, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';
import { useForm } from '../../useHooks/useForm';
import Controls from './../../components/controls/Controls';

const initialFormValues = {
	subject: '',
	firstName: '',
	lastName: '',
	company: '',
	email: '',
	phone: '',
	message: '',
};
function ContactUs() {
	const form = useRef();
	let navigate = useNavigate();
	const [disableValue, setDisableValue] = useState(true);

	const validate = (fieldValues = values) => {
		let temp = { ...errors };

		if ('firstName' in fieldValues)
			temp.firstName = fieldValues.firstName
				? ''
				: 'This field is required';
		if ('lastName' in fieldValues)
			temp.lastName = fieldValues.lastName
				? ''
				: 'This field is required';
		if ('email' in fieldValues)
			temp.email = /$^|.+@.+..+/.test(fieldValues.email)
				? ''
				: 'Email is not valid';
		if ('phone' in fieldValues)
			temp.phone =
				fieldValues.phone.length > 9
					? ''
					: 'Minimum 10 numbers required';
		if ('message' in fieldValues)
			temp.message =
				fieldValues.message.length > 10 ? '' : 'This field is required';
		if ('subject' in fieldValues)
			temp.subject =
				fieldValues.subject.length > 5 ? '' : 'This field is required';

		setErrors({
			...temp,
		});

		if (fieldValues === values)
			return Object.values(temp).every((x) => x === '');
	};
	const {
		values,
		setValues,
		errors,
		setErrors,
		handleInputChange,
		resetForm,
	} = useForm(initialFormValues, true, validate);

	const handleSubmit = (e) => {
		e.preventDefault();
		let id;

		emailjs
			.sendForm(
				'service_qwzv1f7',
				'template_f4c7gxp',
				form.current,
				'user_FXgIzzMR0mHC8QryGYnRJ'
			)
			.then(
				(result) => {
					if (result.status === 200)
						id = result.status
						navigate(`/contactus_success/${id}/${values.firstName}`);
				},
				(error) => {
					navigate(`/contactus_failure/${values.firstName}`);
				}
			);
	};
	useEffect(() => {
		if (
			values.subject.length > 1 &&
			values.firstName.length > 1 &&
			values.lastName.length > 1 &&
			values.email.length > 5 &&
			values.phone.length > 9 &&
			values.message.length > 10
		)
			setDisableValue(false);
		else setDisableValue(true);
	}, [errors]);
	return (
		<Box>
			<form ref={form} onSubmit={handleSubmit} method="POST">
				<Grid
					container
					direction="column"
					spacing={2}
					alignItems="center">
					<Grid item>
						<Typography variant="h4" sx={{ color: 'red' }}>
							Contact Us
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h6">
							Have a questions, feedback, comments?
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="subtitle2" sx={{ mb: 2 }}>
							Please Call or Contact us via the form below, we
							will get back to you as soon as we can.
						</Typography>
					</Grid>
				</Grid>

				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
					}}>
					<Grid
						container
						spacing={2}
						direction="row"
						justifyContent="center"
						lg={6}
						sm={12}
						xs={12}
						// columns={{ lg: 4, md: 12, sm: 12, xs: 12 }}
					>
						<Grid item xs={12}>
							<Controls.Input
								variant="outlined"
								name="subject"
								value={values.subject}
								label="Subject"
								onChange={handleInputChange}
								fullWidth
								error={errors.subject}
							/>
						</Grid>
						<Grid item xs={6}>
							<Controls.Input
								variant="outlined"
								name="firstName"
								value={values.firstName}
								label="First Name"
								onChange={handleInputChange}
								fullWidth
								error={errors.firstName}
							/>
						</Grid>

						<Grid item xs={6}>
							<Controls.Input
								variant="outlined"
								name="lastName"
								value={values.lastName}
								label="Last Name"
								onChange={handleInputChange}
								fullWidth
								error={errors.lastName}
							/>
						</Grid>

						<Grid item xs={12}>
							<Controls.Input
								variant="outlined"
								name="company"
								value={values.company}
								label="Company (Optional)"
								onChange={handleInputChange}
								fullWidth
							/>
						</Grid>

						<Grid item xs={8}>
							<Controls.Input
								variant="outlined"
								name="email"
								value={values.email}
								label="Email"
								onChange={handleInputChange}
								fullWidth
								error={errors.email}
							/>
						</Grid>

						<Grid item xs={4}>
							<Controls.Input
								variant="outlined"
								name="phone"
								value={values.phone}
								label="Phone"
								onChange={handleInputChange}
								fullWidth
								error={errors.phone}
							/>
						</Grid>

						<Grid item xs={12}>
							<Controls.Input
								multiline
								rows={4}
								variant="outlined"
								name="message"
								value={values.message}
								label="Messages"
								onChange={handleInputChange}
								fullWidth
								error={errors.message}
							/>
						</Grid>

						<Grid item xs={6}>
							<Button
								disabled={disableValue ? true : false}
								fullWidth
								variant="contained"
								type="submit"
								// sx={{ backgroundColor: 'blue' }}
							>
								Submit
							</Button>
						</Grid>
						<Grid item xs={6}>
							<Button
								onClick={resetForm}
								fullWidth
								variant="contained"
								type="reset"
								// sx={{ backgroundColor: 'blue' }}
							>
								Reset
							</Button>
						</Grid>
					</Grid>
				</Box>
			</form>
		</Box>
	);
}

export default ContactUs;
