export const FAQObject = [
	{
		id: 0,
		question: 'How big are the facility and parking space?',
		answer: 'The facitly is 3600 sq. ft.  There are plenty of parking space that can used for carry out or delivery.',
	},
	{
		id: 1,
		question: 'How many kitchens are there?',
		answer: 'There are two (2) kitchens at the location.  At any given time, either one (1) chef or two (2) chefs can book the place or one chef can book two kitchens if cooking space and volume are needed.  There is also an OPEN space that can be used for packaging.',
	},
	{
		id: 2,
		question: 'How do we book the place?',
		answer: 'Currently, we are working on solution for online booking. In the meantime, please call 208.841.0019 or send us a message for booking.'
	},
	{
		id: 3,
		question: 'Is there a minimum hour of booking?',
		answer: 'Since the cleaning process is included in the hour of booking, so at the minium, there is at least 2 hours of booking is required.',
	},
	{
		id: 4,
		question: 'Does the cleaning hour include in the booking hour?',
		answer: 'Yes, the cleaning time includes in the booking hour.  Eg. if the cooking time takes about 2.5 hours, then it is advisable to book 3 hours to account for cleaning time.',
	},
	{
		id: 41,
		question: 'What kind of cleaning are expected after each use?',
		answer: 'After each use (check out) we are expecting the kitchen to be returned and clean to the way it was found (check-in).',
	},
	{
		id: 42,
		question:
			'What if we are/were in hurry and did not clean to the way it was?',
		answer: 'If we have to exercise the cleaning process then the cost will be charged to the booking client or deducted from the deposit.',
	},

	{
		id: 5,
		question: 'What is deposit amount required?',
		answer: 'Yes, a deposit mount of $300.00 is requried.  The deposit will be returned after the renting is no longer needed and all the conditions are sastisfied.',
	},
	{
		id: 6,
		question: 'What is the rental hour price?',
		answer: 'The rental price depends on the hour of rental.  A minmum of 2 hours booking is required for each booking.  The rental covers utility usage (gas, water & power) during the rental hours.  For discount and long term rental please contact us.',
	},
	{
		id: 7,
		question:
			'What are the documents that need to be submited prior to booking?',
		answer: 'Since this is a commercial kitchen and its products are tend for the public, so according to the regulation & Health and Welfare, food provider is required to be licenced.  Insurance & business license are also required.  However, if in some cases, some of documents might not be needed, if its products are tend for private use.',
	},
	{
		id: 8,
		question:
			'Can this kitchen be used to prepare food to be sold to public?',
		answer: 'Yes, this is a licensed commercial kitchen that is built for foods to be prepared or sold to to public.',
	},
	{
		id: 9,
		question: 'Can a private or personal chef use this kitchen?',
		answer: 'Yes, any private or personal chef can use this kitchen to prepare large amount of foods or large cooking/event.',
	},
	{
		id: 10,
		question: 'Can this kitchen be used for coooking class?',
		answer: 'Yes, a Chef or any person who have cooking experience can host a cooking class experience.  If such occassion arise, we will need to be notified so such special event can be accommodated.',
	},
	{
		id: 11,
		question: 'What are the benefits of renting a kitchen?',
		answer: 'The number one benefits are LOW OVERHEAD COST, QUICK START-UP & QUICK TURN-AROUND',
	},
	{
		id: 12,
		question: 'Is there a longer contract?',
		answer: 'No, there is no long term contract. Just book the hour you need.',
	},
	{
		id: 13,
		question: 'As we grow our business, can we book long term?',
		answer: 'Yes, we support repeated booking so that you can keep the same recurrent booking hours on each day.',
	},
	{
		id: 14,
		question: 'What is the kitchen hour?',
		answer: 'We are open all year long, 24/7.',
	},
	{
		id: 15,
		question: 'Can we bring in our cooking equipment?',
		answer: 'Since some equipments require certain type of gas pressure as well as certain type of electrical outlet if it is a power equipment.  If such case arise, we will evaluate on case basis.',
	},
	{
		id: 100,
		question: 'Any other question?',
		answer: 'If you have other questions, please send us you questions thru the Contact Us page/link',
	},
];
