export const KitchenObject = [
	{
		id: 1,

		title: '',
		img: '/images/Kitchen/theWhat.jpg',
	},
	{
		id: 2,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4895.jpg',
	},
	{
		id: 3,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4896.jpg',
	},
	{
		id: 4,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4897.jpg',
	},
	{
		id: 5,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4898.jpg',
	},
	{
		id: 6,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4900.jpg',
	},
	{
		id: 7,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4904.jpg',
	},
	{
		id: 8,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4909.jpg',
	},
	{
		id: 9,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4912.jpg',
	},

	{
		id: 10,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4913.jpg',
	},
	{
		id: 11,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4917.jpg',
	},
	{
		id: 12,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4918.jpg',
	},
	{
		id: 13,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4919.jpg',
	},
	{
		id: 14,

		title: '',
		img: '/images/Kitchen/thumbnail_IMG_4920.jpg',
	},
];
