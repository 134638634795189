import Input from './Input';
// import RadioGroup from './RadioGroup';
// import Select from './Select';
// import CheckBox from './CheckBox';
// // import DatePicker from './DatePicker';
import Button from './Button';
// import ActionButton from './ActionButton';
// import Select3 from './Select3';

const Controls = {
	Input,
	// RadioGroup,
	// Select,
	// CheckBox,
	// // DatePicker,
	Button,
	// ActionButton,
	// Select3,
};

export default Controls;
