import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
// import theme from './components/Theme/Theme';
import theme from './Theme/Theme';
import UserContextProvider from './../src/contexts/UserContext';

import HomePage from './pages/HomPage/HomPage';
import ContactUs from './pages/FooterPage/ContactUs';
import ContactUsSuccess from './pages/FooterPage/ContactUsSuccess';
import ContactUsFailure from './pages/FooterPage/ContactUsFailure';
import KitchenEquipment from './pages/FooterPage/KitchenEquipment';
import FAQ from './pages/FooterPage/FAQ';
import AboutUs from './pages/FooterPage/AboutUs';
//custom control
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Booking from './components/Booking/Booking';
import KitchenCarouselDisplay from './components/Carousel/KitchenCarouselDisplay';
function App() {
	return (
		<ThemeProvider theme={theme}>
			<UserContextProvider>
				<Box
					sx={{
						'.MuiBox-root': { position: 'sticky' },
					}}>
					<Navbar />
					<KitchenCarouselDisplay />
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="about_us" element={<AboutUs />} />
						<Route path="/booking" element={<Booking />  } />
						<Route path="/contactus" element={<ContactUs />} />
						<Route
							path="/contactus_success/:id/:firstName"
							element={<ContactUsSuccess />}
						/>
						<Route
							path="/equipment"
							element={<KitchenEquipment />}
						/>
						<Route
							path="/contactus_failure/:firstName"
							element={<ContactUsFailure />}
						/>
						<Route path="/faq" element={<FAQ />} />
					</Routes>

					<Footer />
				</Box>
			</UserContextProvider>
		</ThemeProvider>
	);
}

export default App;
