import React from 'react';
import {
	Grid,
	Box,
	Paper,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Divider,
} from '@mui/material';
import { Link } from 'react-router-dom';

import theme from './../../Theme/Theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FAQObject } from './FAQData';

export default function FAQ() {
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				direction: 'column',
			}}>
			<Grid
				container
				lg={6}
				md={12}
				sm={12}
				xs={12}
				justifyContent="center">
				<Grid item sx={{ mb: 4 }}>
					<Typography
						variant="h6"
						component={Link}
						to="/contactus"
						sx={{
							color: theme.palette.primary.main,
							textDecoration: 'none',
						}}>
						We hope these frequent askd questions and answers can
						help to answers the majority of the mostly asks. If they
						are not covered here, please feel free to Contact Us.
					</Typography>
				</Grid>
				{FAQObject.map((item) => (
					<Box key={item.id} sx={{ mb: 1, width: '100%' }}>
						<Grid item>
							<Accordion
								sx={{
									backgroundColor: 'lightgrey',
								}}
								expanded={expanded === `${item.id}`}
								onChange={handleChange(`${item.id}`)}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}>
									<Typography
										variant="h5"
										sx={{
											// flexShrink: 0,
											fontWeight: 500,
										}}>
										{item.question}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography
										variant="h6"
										sx={{
											fontWeight: 300,
										}}>
										{item.answer}
									</Typography>
								</AccordionDetails>
							</Accordion>
						</Grid>
						<Divider />
					</Box>
				))}
			</Grid>
		</Box>
	);
}
