import React from 'react';
import { Grid, Box } from '@mui/material';

import theme from './../../Theme/Theme'

//custom components
import FooterAbout from './FooterAbout';

import FooterInformation from './FooterInformation';
import FooterService from './FooterService';
import FooterBottom from './FooterBottom';

function Footer() {
	return (
		<Box sx={{ backgroundColor: theme.palette.primary.main }}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					mt: 10,
					ml: '16vw',
					mr: '16vw',
					mb: 5,
					backgroundColor: theme.palette.primary.main,
				}}>
				<Grid
					container
					justifyContent="center"
					spacing={{ xs: 2, md: 4 }}
					columns={{ xs: 4, sm: 8, md: 12 }}>
					<Grid item xs={12} sm={12} md={4}>
						<FooterAbout />
					</Grid>

					<Grid item xs={12} sm={12} md={4}>
						<FooterInformation />
					</Grid>
					<Grid item xs={12} sm={12} md={4}>
						<FooterService />
					</Grid>
				</Grid>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					backgroundColor: 'black',
				}}>
				<FooterBottom />
			</Box>
		</Box>
	);
}

export default Footer;

// direction="column"
// 				container
//
// columns = {{ xs: 4, sm: 8, md: 12 }}>
