export const KitchenEquipmentObject = [
	{
		id: 1,
		eq: 'Gas Range Burner',
		desc: 'A combination of open top 6 burners and a spacious oven',
	},
	{
		id: 2,
		eq: 'Floor Gas Fryer',
		desc: 'A high performance with 2 mesh fry baskets',
	},
	{
		id: 3,
		eq: 'Countertop Gas Griddle',
		desc: 'Heavy duty stainless steel griddle',
	},
	{
		id: 4,
		eq: 'Gas Countertop Radiant Charbroiler',
		desc: 'Powerful, Efficient with 2 independent controlled burner.',
	},
	{
		id: 5,
		eq: 'Full Size Natural Gas Convection Oven',
		desc: 'Powerful Performance High BTU Natural Gas Convection Oven',
	},
	{
		id: 6,
		eq: 'Natural Gas Stock Pot Range',
		desc: 'High Power Natural Gas Stock Pot',
	},
	{
		id: 7,
		eq: 'Steam/Heated Holding Food Cabinet',
		desc: 'Electric Full size non-insulated steam/heated holding cabinet ',
	},
	{
		id: 8,
		eq: 'Counter top microwave',
		desc: 'Electric Counter top microwave',
	},
];

export const KitchenAccessoryObject = [
	{
		id: 1,
		eq: 'Electric Meat Slicer',
		desc: 'Manual Gravity Feed Meat Slicer',
	},
	{
		id: 2,
		eq: 'Electric Meat Grinder',
		desc: 'Commercial Meat Grinder great for sausages, hamburgers and others',
	},
	{
		id: 3,
		eq: 'Commercial Mixer',
		desc: '20 Qt. Planetary Stand Mixer with Guard or 6 Qt. Counter Top',
	},
	{
		id: 4,
		eq: 'Commercial Blender',
		desc: 'Commercial Blender with Toggle Control, Variable Speed',
	},
	{
		id: 5,
		eq: 'Commercial Food Processor',
		desc: 'Heavy duty commercial food processor with cutting & mixing',
	},
	{
		id: 6,
		eq: 'Manual Can Opener',
		desc: 'Heavy-Duty #10 Manual Can Opener',
	},
	{
		id: 7,
		eq: 'Bun Pan/ Perforated Bun',
		desc: 'Wire in Rim Aluminum Bun Pan / Perforated Bun / Sheet Pan',
	},
	{
		id: 8,
		eq: 'Sheet Pan Rack',
		desc: 'Mobile 20-Sheet Pan Rack',
	},
	{
		id: 9,
		eq: 'Hand Mixer',
		desc: 'immersion blender great for chopping, mixing or bleding',
	},
];

export const KitchenShareObject = [
	{
		id: 1,
		eq: 'High Temperature Dishwasher',
		desc: 'Full size high temp. dishwasher',
	},
	{
		id: 2,
		eq: 'Ice Machine',
		desc: 'High Capacity Ice machine',
	},
];
