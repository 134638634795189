import React from 'react';
import { TextField } from '@mui/material';
// import theme from './../Theme/Theme';
export default function Input(props) {
	const { name, label, value, error, onChange, ...other } = props;
	return (
		<TextField
			// sx={{
			// 	border: 1,
			// 	borderColor: theme.palette.grey[400],
			// 	borderRadius: 1,
			// 	'& .MuiFilledInput-root': {
			// 		backgroundColor: theme.palette.common.white,
			// 	},
			// }}
			fullWidth
			variant="outlined"
			label={label}
			name={name}
			value={value}
			onChange={onChange}
			// InputProps={{
			// 	disableUnderline: true,
			// }}
			InputLabelProps={{
				shrink: true,
			}}
			// error
			// helperText="Some validation error"
			// //the 2 error & helperTxt above equivalent to below
			{...other}
			{...(error && { error: true, helperText: error })}
		/>
	);
}
