import React from 'react';
import { Grid, Box, Typography, Paper, Divider } from '@mui/material';

// import theme from './../../components/Theme/Theme';
import theme from './../../Theme/Theme';

import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import {
	KitchenEquipmentObject,
	KitchenAccessoryObject,
	KitchenShareObject,
} from './KitchenEquipmentData';

export default function KitchenEquipment() {
	return (
		// <Box sx={{ display: 'flex', justifyContent: 'center' }}>
		<Grid container justifyContent="center">
			<Grid
				item
				container
				direction="column"
				lg={8}
				md={8}
				sm={12}
				xs={12}>
				<Grid item>
					<Typography
						variant="h4"
						sx={{ color: theme.palette.primary.main, mb: 2 }}>
						Each kitchen is equiped with the items below:
					</Typography>
				</Grid>
				<Grid>
					<Typography variant="subtitle2" sx={{ pr: 12, mb: 2 }}>
						The usage of the items below are included in the rent.
						Each chef can use and return these items in the same
						condition as first found.
					</Typography>
				</Grid>
				<Grid>
					<Typography
						variant="h5"
						sx={{ color: theme.palette.primary.main }}>
						Cooking Equipments:
					</Typography>
				</Grid>
			</Grid>
			<Grid
				item
				container
				alignItem="center"
				direction="column"
				lg={8}
				md={8}
				sm={12}
				xs={12}>
				{KitchenEquipmentObject.map((item) => (
					<div key={item.id}>
						<Grid
							item
							sx={{
								display: 'flex',
								textDecoration: 'none',
								alignItems: 'center',
							}}>
							<ArrowRightIcon />
							<Typography variant="h6">{item.eq}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">
								&emsp; {item.desc}
							</Typography>
						</Grid>
					</div>
				))}
			</Grid>
			<Divider sx={{ width: '80%', m: 4 }} />
			<Grid
				item
				container
				direction="column"
				lg={8}
				md={8}
				sm={12}
				xs={12}>
				<Grid item>
					<Typography
						variant="h5"
						sx={{ color: theme.palette.primary.main }}>
						Food Preparation Equipments:
					</Typography>
				</Grid>
			</Grid>
			<Grid container direction="column" lg={8} md={8} sm={12} xs={12}>
				{KitchenAccessoryObject.map((item) => (
					<div key={item.id}>
						<Grid
							item
							sx={{
								display: 'flex',
								textDecoration: 'none',
								alignItems: 'center',
							}}>
							<ArrowRightIcon />
							<Typography variant="h6">{item.eq}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">
								&emsp; {item.desc}
							</Typography>
						</Grid>
					</div>
				))}
			</Grid>
			<Divider sx={{ width: '80%', m: 4 }} />
			<Grid container direction="column" lg={8} md={8} sm={12} xs={12}>
				<Grid item>
					<Typography
						variant="h5"
						sx={{ color: theme.palette.primary.main }}>
						Ice Machine & Dishwasher:
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="subtitle2">
						Commonly usage among kitchens
					</Typography>
				</Grid>
			</Grid>
			<Grid container direction="column" lg={8} md={8} sm={12} xs={12}>
				{KitchenShareObject.map((item) => (
					<div key={item.id}>
						<Grid
							item
							sx={{
								display: 'flex',
								textDecoration: 'none',
								alignItems: 'center',
							}}>
							<ArrowRightIcon />
							<Typography variant="h6">{item.eq}</Typography>
						</Grid>
						<Grid item>
							<Typography variant="subtitle1">
								&emsp; {item.desc}
							</Typography>
						</Grid>
					</div>
				))}
			</Grid>{' '}
		</Grid>
	);
}
